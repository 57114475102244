function Testimonial() {
  return(
    <div className="testimonial">
      <div className="container">
        <div className="section-header text-center">
          <p>Témoignages</p>
          <h2>Ce qu'ils <span>disent</span> à propos de nous</h2>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="testimonial-item">
              <h3>Jean-Pierre ATANGANA</h3>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                L'accueil chez <strong className="c0">V.Arost</strong> était au top, structure sérieuse et volontaire.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-item">
              <h3>Corinne MBAZO</h3>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                Nous avons eu recours à <strong className="c0">V.Arost</strong> pour la rénovation de notre maison.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-item mt-4">
              <h3>François ATSONFACK</h3>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                Enchantée par le professionnalisme et la qualité de service. Je recommande.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-item mt-4">
              <h3>Paméla HONLACK</h3>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                Excellents conseils, réactivité face à mes demandes et travail conforme à mes attentes.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-item mt-4">
              <h3>Dieudonné FOKAM</h3>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                L'équipe à su cerner très vite nos envies, très bonnes expériences.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="testimonial-item mt-4">
              <h3>Marc SONA</h3>
              <p>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                Disponible et à l'écoute, qualité irréprochable.
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;