import { NavLink } from "react-router-dom";

function Recentpostitem(props) {
  return(
    <div className="post-item">
      <div className="post-img">
        <img src={props.post.img} alt={props.post.title} />
      </div>
      <div className="post-text">
        <NavLink to={props.post.url}>{props.post.title}</NavLink>
        <div className="post-meta">
          <p>Par <NavLink href="">{props.post.author}</NavLink></p>
          <p>Dans <NavLink href="">{props.post.field}</NavLink></p>
        </div>
      </div>
    </div>
  );
}

export default Recentpostitem;