import Pageheader from "../../components/Pageheader";

function Contact() {
  const page = {
    url: "/contact-us",
    title: "Contactez-nous",
  }
  
  const home = {
    url: "/home",
    title: "Accueil",
  }

  return(
    <>
      <Pageheader home={home} page={page} />


      <div className="contact wow fadeInUp">
        <div className="container">
          <div className="section-header text-center">
            <p>Vous avez un projet en tête ?</p>
            <h2>Confiez le nous !</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="contact-info">
                <div className="contact-item rounded">
                  <i className="flaticon-address"></i>
                  <div className="contact-text">
                    <h2>Location</h2>
                    <p>Bonamoussadi - Ecole Maternelle gpe 2</p>
                  </div>
                </div>
                <div className="contact-item rounded">
                  <i className="flaticon-call"></i>
                  <div className="contact-text">
                    <h2>Telephone</h2>
                    <p>+237 6 77 75 30 51 | +237 6 95 52 24 25</p>
                  </div>
                </div>
                <div className="contact-item rounded">
                  <i className="flaticon-send-mail"></i>
                  <div className="contact-text">
                    <h2>Email</h2>
                    <p>info@v-arost.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-form">
                <div id="success"></div>
                <form name="sentMessage" id="contactForm" novalidate="novalidate">
                  <div className="control-group">
                    <input type="text" name="name" className="form-control rounded mt-2" placeholder="Votre Nom" required="required" />
                  </div>
                  <div className="control-group">
                    <input type="email" name="email" className="form-control rounded mt-2" placeholder="Votre Email" required="required" />
                  </div>
                  <div className="control-group">
                    <input type="text" className="form-control rounded mt-2" name="subject" placeholder="Sujet" required="required" />
                  </div>
                  <div className="control-group">
                    <textarea className="form-control rounded mt-2" name="message" placeholder="Message" required="required" ></textarea>
                  </div>
                  <div>
                    <button className="btn rounded mt-2" type="submit">Envoyer le message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;