import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import publicRoutes from "./routes/publicRoutes";
import Publiclayout from "./layouts/public/Publiclayout";


axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
axios.defaults.headers.patch["Content-Type"] = "multipart/form-data";
axios.defaults.headers.delete["Content-Type"] = "multipart/form-data";

axios.defaults.withCredentials = true;

function App() {
  if (localStorage.getItem("auth_admin_token")) {
    axios.interceptors.request.use(function (config){
      const token = localStorage.getItem("auth_admin_token");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
      return config;
    });
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" name="Public" element={<Publiclayout/>} >
            {publicRoutes.map((route, index)=>{
                return (
                  route.element && (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                )
              })}
              <Route
                index
                element={<Navigate to="/home" replace />}
              /> 
              <Route
                path="*"
                exact={true}
                element={<Navigate to="/home" replace />}
              /> 
          </Route> 

        </Routes>
      </Router>
    </div>
  );
}

export default App;
