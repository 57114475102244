import Pagepagination from "../../components/Pagepagination";
import Blogitem from "../../components/Blogitem";
import Pageheader from "../../components/Pageheader";

function Blog() {
  const page = {
    url: "/blog",
    title: "Notre Blog",
  }
  
  const home = {
    url: "/home",
    title: "Accueil",
  }
  
  const articles = [
    {
      img: "img/pexels-erik-karits-11032990.jpg",
      title: "Comment se débarrasser des cafards",
      author: "Admin",
      field: "Traitement phytosanitaire",
      text: "INSECTES - Les cafards se sont installés chez vous ? Bicarbonate de sodium, vin... Voici deux recettes naturelles pour les faire fuir.",
    },
    {
      img: "img/pexels-tima-miroshnichenko-6196223.jpg",
      title: "Que faire s’il y a des moisissures dans votre milieu de travail?",
      author: "Admin",
      field: "Nettoyage ménager",
      text: "L’inhalation de spores, de moisissures ou de fragments de moisissures peut rendre malade ou aggraver certains problèmes de santé.",
    },
    {
      img: "img/pexels-creative-vix-7283.jpg",
      title: "5 conseils pour bien entretenir son jardin",
      author: "Admin",
      field: "Entretien des espaces verts",
      text: "Un beau jardin, c'est toujours agréable à contempler... mais ça demande tout de même un minimum d'entretien.",
    },
  ];

  return(
    <>
      <Pageheader home={home} page={page} /> 
      
      <div className="blog">
        <div className="container">
          <div className="section-header text-center">
            <p>Actualités</p>
            <h2>Nos derniers articles</h2>
          </div>
          <div className="row blog-page">
          {
            articles.map((article, index) =>
            <Blogitem key={index} 
              title={article.title} 
              img={article.img} 
              text={article.text} 
              author={article.author} 
              field={article.field} />
            )
          }
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;