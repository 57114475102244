function Pageheader(props) {
  return(
    <div className="page-header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{props.page.title}</h2>
          </div>
          <div className="col-12">
            <a href={props.home.url}>{props.home.title}</a>
            <a href={props.page.url}>{props.page.title}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pageheader;