function Aboutus() {
  return(
    <>
      <div className="about wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="about-img">
                <img src="img/nesa-by-makers-APJkBRQJuj0-unsplash.jpg" alt="Image" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="section-header text-left">
                <p>V.Arost</p>
                <h2>Prestations de services et commerce général.</h2>
              </div>
              <div className="about-text">
                <p>
                  V.Arost s’est donnée pour vocation la volonté manifeste d’atteindre et de démontrer un bon niveau de performance. Elle a pris à cet effet des dispositions pour maitriser l’impact de ses activités sur l’environnement dans le strict respect de l’art et la réglementation qui s’imposent dans le secteur d’activité.
                </p>
                <p>
                  V.Arost, prestataire de services pour les grandes et moyennes entreprises, les unités industrielles de toute taille exerçant en agro-alimentaire, en énergie, dans les mines, etc…
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      <div className="fact">
        <div className="container-fluid">
          <div className="row counters">
            <div className="col-md-6 fact-left wow slideInLeft">
              <div className="row">
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-worker"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">18</h2>
                    <p>Travailleurs qualifiés</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-building"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">2013</h2>
                    <p>Année de création</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 fact-right wow slideInRight">
              <div className="row">
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-address"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">250</h2>
                    <p>Clients Satisfaits</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fact-icon">
                    <i className="flaticon-crane"></i>
                  </div>
                  <div className="fact-text">
                    <h2 data-toggle="counter-up">06</h2>
                    <p>Métiers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      <div className="service">
        <div className="container">
          <div className="section-header text-center">
            <p>Nos Services</p>
            <h2>Aucun projet n’est ni trop grand, ni trop petit.</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/pexels-tima-miroshnichenko-6195122.jpg" alt="Image" />
                  <div className="service-overlay">
                    <p>
                      Nettoyage ménager et industriel.
                    </p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>Nettoyage ménager et industriel</h3>
                  <a className="btn" href="img/service-1.jpg" data-lightbox="service">+</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/pexels-xavier-messina-12491630.jpg" alt="Image" />
                  <div className="service-overlay">
                    <p>
                      Création et entretien des espaces verts.
                    </p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>Création et entretien des espaces verts</h3>
                  <a className="btn" href="img/service-2.jpg" data-lightbox="service">+</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/pexels-anna-shvets-5217889.jpg" alt="Image" />
                  <div className="service-overlay">
                    <p>
                      Distribution de produits de nettoyage et d’hygiène.
                    </p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>Distribution de produits de nettoyage et d’hygiène</h3>
                  <a className="btn" href="img/service-3.jpg" data-lightbox="service">+</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/pexels-tima-miroshnichenko-6195288.jpg" alt="Image" />
                  <div className="service-overlay">
                    <p>
                      Traitement phytosanitaire.
                    </p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>Traitement phytosanitaire</h3>
                  <a className="btn" href="img/service-4.jpg" data-lightbox="service">+</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/nesa-by-makers-IgUR1iX0mqM-unsplash.jpg" alt="Image" />
                  <div className="service-overlay">
                    <p>
                      Prestations de Services.
                    </p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>Prestations de Services</h3>
                  <a className="btn" href="img/service-4.jpg" data-lightbox="service">+</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
              <div className="service-item">
                <div className="service-img">
                  <img src="img/pexels-tima-miroshnichenko-6169192.jpg" alt="Image" />
                  <div className="service-overlay">
                    <p>
                      Commerce Général.
                    </p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>Commerce Général</h3>
                  <a className="btn" href="img/service-4.jpg" data-lightbox="service">+</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutus;