import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Topbar from "../../components/Topbar";


function Publiclayout() {
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return(
    <>
      <Topbar topFunction={topFunction}/>
      <Navbar topFunction={topFunction}/>
      <Outlet/>   
      <Footer topFunction={topFunction}/>
    </>
  );
}

export default Publiclayout;