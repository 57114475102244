import { NavLink } from "react-router-dom";

function Navbar() {
  return(
    <div className="nav-bar">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
          <NavLink to="#" className="navbar-brand">MENU</NavLink>
          <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div className="navbar-nav mr-auto">
              <NavLink to="/home" className="nav-item nav-link">Accueil</NavLink>
              <NavLink to="/about-us" className="nav-item nav-link">À propos de nous</NavLink>
              <NavLink to="/blog" className="nav-item nav-link">Blog</NavLink>
              <NavLink to="/contact" className="nav-item nav-link">Contactez-nous</NavLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;