import Aboutus from "../../components/Aboutus";
import Pageheader from "../../components/Pageheader";

function About() {
  const page = {
    url: "/aboutus",
    title: "À propos de nous",
  }
  
  const home = {
    url: "/home",
    title: "Accueil",
  }

  return(
    <>
      <Pageheader home={home} page={page} /> 

      <Aboutus />
    </>
  );
}

export default About;