import Aboutus from "../../components/Aboutus";
import Blogitem from "../../components/Blogitem";
import Testimonial from "../../components/Testimonial";

function Index () {
  const articles = [
    {
      img: "img/pexels-erik-karits-11032990.jpg",
      title: "Comment se débarrasser des cafards",
      author: "Admin",
      field: "Traitement phytosanitaire",
      text: "INSECTES - Les cafards se sont installés chez vous ? Bicarbonate de sodium, vin... Voici deux recettes naturelles pour les faire fuir.",
    },
    {
      img: "img/pexels-tima-miroshnichenko-6196223.jpg",
      title: "Que faire s’il y a des moisissures dans votre milieu de travail?",
      author: "Admin",
      field: "Nettoyage ménager",
      text: "L’inhalation de spores, de moisissures ou de fragments de moisissures peut rendre malade ou aggraver certains problèmes de santé.",
    },
    {
      img: "img/pexels-creative-vix-7283.jpg",
      title: "5 conseils pour bien entretenir son jardin",
      author: "Admin",
      field: "Entretien des espaces verts",
      text: "Un beau jardin, c'est toujours agréable à contempler... mais ça demande tout de même un minimum d'entretien.",
    },
  ];

  return(
    <>
      <div id="carousel" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carousel" data-slide-to="0" className="active"></li>
          <li data-target="#carousel" data-slide-to="1"></li>
          <li data-target="#carousel" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="img/pexels-xavier-messina-12491632.jpg" alt="Carousel Image" />
            <div className="carousel-caption">
              <p className="animated fadeInRight">Nous sommes la</p>
              <h1 className="animated fadeInLeft">Société V.Arost</h1>
            </div>
          </div>

          <div className="carousel-item">
            <img src="img/pexels-anna-shvets-3727457.jpg" alt="Carousel Image" />
            <div className="carousel-caption">
              <p className="animated fadeInRight">Nous sommes spécialisés dans la</p>
              <h1 className="animated fadeInLeft">Prestations de services diverses et commerce général.</h1>
            </div>
          </div>

          <div className="carousel-item">
            <img src="img/cytonn-photography-n95VMLxqM2I-unsplash.jpg" alt="Carousel Image" />
            <div className="carousel-caption">
              <p className="animated fadeInRight">Nous sommes dignes de confiance</p>
              <h1 className="animated fadeInLeft">Confiez nous vos projets, nous les réaliserons.</h1>
            </div>
          </div>
        </div>

        <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Précedent</span>
        </a>
        <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Suivant</span>
        </a>
      </div>
      
      
      <div className="feature wow fadeInUp" data-wow-delay="0.1s">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-worker"></i>
                </div>
                <div className="feature-text">
                  <h3>10 années expériences</h3>
                  <p>Expérience confirmée riche de plus 10 années d’expérience.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-building"></i>
                </div>
                <div className="feature-text">
                  <h3>Respect des normes internationales</h3>
                  <p>V.Arost met à votre disposition un service professionnel de qualité, aligné aux normes internationales.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className="flaticon-call"></i>
                </div>
                <div className="feature-text">
                  <h3>24/7 Support</h3>
                  <p>Notre engagement à vous satisfaire nous obligent à travailler avec professionnalisme et à vous garantir un service après-vente de qualité.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      <Aboutus/>


      <Testimonial/>

      
      <div className="blog">
        <div className="container">
          <div className="section-header text-center">
            <p>Actualités</p>
            <h2>Nos derniers articles</h2>
          </div>
          <div className="row">
          {
            articles.map((article, index) =>
            <Blogitem key={index} 
              title={article.title} 
              img={article.img} 
              text={article.text} 
              author={article.author} 
              field={article.field} />
            )
          }    
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;