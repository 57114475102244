import { Link } from "react-router-dom";

function Footer() {
  return(
    <div className="footer wow fadeIn" data-wow-delay="0.3s">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="footer-contact">
              <h2>Contactez-nous</h2>
              <p><i className="fa fa-map-marker-alt"></i>Bonamoussadi - Ecole Maternelle gpe 2</p>
              <p><i className="fa fa-address-card"></i>12279 Douala</p>
              <p><i className="fa fa-phone-alt"></i>+237 6 77 75 30 51 | +237 6 95 52 24 25</p>
              <p><i className="fa fa-envelope"></i>info@v-arost.com</p>
              <div className="footer-social">
                <Link to=""><i className="fab fa-twitter"></i></Link>
                <Link to=""><i className="fab fa-facebook-f"></i></Link>
                <Link to=""><i className="fab fa-youtube"></i></Link>
                <Link to=""><i className="fab fa-instagram"></i></Link>
                <Link to=""><i className="fab fa-linkedin-in"></i></Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="footer-link">
              <h2>Nos Services</h2>
              <Link to="">Nettoyage ménager et industriel</Link>
              <Link to="">Création et entretien des espaces verts</Link>
              <Link to="">Distribution de produits de nettoyage et d’hygiène</Link>
              <Link to="">Traitement phytosanitaire</Link>
              <Link to="">Prestations de Services</Link>
              <Link to="">Commerce Général</Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="footer-link">
              <h2>Liens utiles</h2>
              <Link to="/home">Accueil</Link>
              <Link to="/about-us">À propos de nous</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/conctact">Contactez-nous</Link>
              <Link to="/login">Se connecter</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container copyright">
        <div className="row">
          <div className="col-md-6">
            <p>&copy; <Link to="#">V.Arost</Link>, Tous droits réservés.</p>
          </div>
          <div className="col-md-6">
            <p>Conçu par <Link to="mailto:fredkameetome@gmail.com">Freddy KAME</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;