function Blogitem (props) {
  return(
    <div className="col-lg-4 col-md-6 blog-item">
      <div className="blog-img">
        <img src={props.img} alt={props.title}  />
      </div>
      <div className="blog-title">
        <h3>{props.title}</h3>
        <a className="btn" href="">+</a>
      </div>
      <div className="blog-meta">
        <p>Publié par <a href="">{props.author}</a></p>
        <p>Dans <a href="">{props.field}</a></p>
      </div>
      <div className="blog-text">
        <p>
          {props.text}
        </p>
      </div>
    </div>
  );
}

export default Blogitem;