import About from "../layouts/public/About";
import Blog from "../layouts/public/Blog";
import Blogsingle from "../layouts/public/Blogsingle";
import Contact from "../layouts/public/Contact";
import Index from "../layouts/public/Index";


const publicRoutes = [
  {path:"/home", exact:true, name:"Accueil", element: Index},
  {path:"/about-us", exact:true, name:"À propos de nous", element: About},
  {path:"/contact", exact:true, name:"Contactez-nous", element: Contact},
  {path:"/blog", exact:true, name:"Notre Blog", element: Blog},
  {path:"/blogsingle", exact:true, name:"Notre Blog", element: Blogsingle},

];

export default publicRoutes;